<template>
  <v-card>
    <v-card-title>
      <form>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6">
            <v-autocomplete
              label="Tahun"
              :items="years"
              item-value="years"
              v-model="cari.tahun"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-btn @click="show()" class="mr-4" color="primary">
              <v-icon left> mdi-magnify </v-icon>Show
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="dataReg"
      :search="search"
      item-key="id_pst"
      class="elevation-1"
      :expanded.sync="expanded"
      :single-expand="singleExpand"
      show-expand
    >
      <template v-slot:[`top`]>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
          append-icon="mdi-magnify"
        >
        </v-text-field>
        <v-dialog v-model="dialog" max-width="800px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">Edit Kelas Prodi</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      :items="prodies"
                      color="white"
                      item-text="nama_program_studi"
                      item-value="kode_program_studi"
                      label="Program Studi"
                      v-model="kelasUpd.kode_program_studi"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-autocomplete
                      label="Kelas"
                      :items="kelas"
                      item-text="name"
                      item-value="id"
                      v-model="kelasUpd.kelas"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="UpdateKls"
                >Update</v-btn
              >
            </v-card-actions>
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
            >
              Jika Melakukan Update Prodi Silahkan Generate Kembali Nim
              MAhasiswa Untuk Menyesuaikan Nim Pada Prodi Baru !!!
            </v-alert>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.aksi`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="setNim(item)"
              class="sm-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Set Nim</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
              class="sm-2 ml-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon small> fas fa-edit </v-icon>
            </v-btn>
          </template>
          <span>Edit Prodi/Kelas</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-btn
            v-if="item.bukti_bayar_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="kwitansi(item.bukti_bayar_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Kwitansi
          </v-btn>
          <v-btn
            v-if="item.ijazah_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="pp(item.ijazah_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Ijazah
          </v-btn>
          <v-btn
            v-if="item.kk_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="kk(item.kk_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Kartu keluarga
          </v-btn>
          <v-btn
            v-if="item.ktp_upload !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="ktp(item.ktp_upload)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> File Ktp
          </v-btn>
          <v-btn
            v-if="item.bukti_reg !== null"
            class="ma-2"
            tile
            x-small
            outlined
            color="success"
            @click="BuktiReg(item.bukti_reg)"
          >
            <v-icon left> mdi-folder-multiple-image</v-icon> Kwitansi Reg Ulang
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <img-viewer ref="viewer" />
  </v-card>
</template>
<script>
import ImgViewer from "../components/ImgViewer";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DafarUlang",
  components: {
    ImgViewer,
  },
  data: () => ({
    expanded: [],
    singleExpand: true,
    editedIndex: -1,
    dialog: false,
    search: "",
    cari: {
      tahun: "",
    },
    kelasUpd: {
      kelas: "",
      kode_program_studi: "",
    },
    kelas: [
      { name: "Khusus", id: "K" },
      { name: "Reguler", id: "R" },
    ],
  }),
  created() {
    // this.loadProdi();
  },
  computed: {
    ...mapGetters("reg", ["dataReg"]),
    ...mapGetters("reg", ["prodi"]),
    prodies() {
      return Object.keys(this.prodi).map((k) => {
        let obj = this.prodi[k];
        return obj;
      });
    },
    years() {
      let year = new Date().getFullYear();
      return Array.from(
        {
          length: year - 2018,
        },
        (value, index) => 2019 + index
      );
    },
    headers() {
      return [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Nama Peserta",
          value: "nm_pst",
        },
        {
          text: "Nim",
          value: "nim",
        },
        {
          text: "Kelas",
          value: "kelas",
        },
        {
          text: "Prodi",
          value: "nama_program_studi",
        },
        {
          text: "Kaos",
          value: "kaos",
        },
        {
          text: "Almamater",
          value: "almamater",
        },
        {
          text: "Aksi",
          value: "aksi",
        },
      ];
    },
  },
  methods: {
    ...mapActions("reg", ["loadProdi"]),
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    show() {
      let formData = this.formData(this.cari);
      this.$store.dispatch("reg/loadDataReg", formData);
    },
    setNim(item) {
      this.$confirm(
        "Anda Yakin Memberikan Nim Kepada :" + item.nm_pst + "?",
        "Confirm",
        "question"
      )
        .then(() => {
          let formData = this.formData({ id_pst: item.id_pst });
          this.$store
            .dispatch("reg/setNimPst", formData)
            .then((succ) => {
              this.$alert(succ.message, "Success", "success");
              this.show();
            })
            .catch((err) => {
              this.$alert(err.response.data, "Error", "error");
            });
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    close() {
      this.dialog = false;
      // this.show();
    },
    editItem(item) {
      this.editedIndex = this.dataReg.indexOf(item);
      this.kelasUpd = Object.assign({}, item);
      this.dialog = true;
      this.loadProdi();
    },
    UpdateKls() {
      this.$store.dispatch("reg/updateKlsProdi", this.kelasUpd).then((succ) => {
        this.$alert(succ.message, "Success", "success");
        this.close();
        this.show();
      });
    },
    pp(pp) {
      const imaga = [
        {
          thumbnail: pp,
          source: pp,
        },
      ];
      this.$refs.viewer.show(imaga);
    },
    kwitansi(k) {
      const imageK = [
        {
          thumbnail: k,
          source: k,
        },
      ];
      this.$refs.viewer.show(imageK);
    },
    kk(kk) {
      const imageKK = [
        {
          thumbnail: kk,
          source: kk,
        },
      ];
      this.$refs.viewer.show(imageKK);
    },
    ktp(ktp) {
      const imageKtp = [
        {
          thumbnail: ktp,
          source: ktp,
        },
      ];
      this.$refs.viewer.show(imageKtp);
    },
    BuktiReg(br) {
      const imageBR = [
        {
          thumbnail: br,
          source: br,
        },
      ];
      this.$refs.viewer.show(imageBR);
    },
  },
};
</script>
